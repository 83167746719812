import { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";
import { getTileClassNameFunction } from "./tileColorSelection";
import { Grid, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { formatDate } from "../../helpers";
import { setSelectedDates } from "../../../../features/timesheetSlice";
import { getMonthStatus } from "../../helpers";
function addMonths(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

function CustomCalendar({
  selectedDates,
  dispatch,
  timesheetData,
  selectedMonth,
  setSelectedMonth,
  desactivateCalendar,
}) {
  // To be done: Pass month status instead of hardcoding
  const [monthStatus, setMonthStatus] = useState("");
  const selectRange = true;

  const handleDateChange = (newDate) => {
    if (selectRange) {
      dispatch(setSelectedDates(newDate));
      // setSelectedDates(newDate); // Pass the selected date to the parent component
    } else {
      dispatch(setSelectedDates([newDate, newDate]));
      // setSelectedDates([newDate, newDate]);
    }
  };

  useEffect(() => {
    // Fetch month status
    getMonthStatus({
      month: selectedMonth.getMonth() + 1,
      year: selectedMonth.getFullYear(),
    })
      .then((status) => {
        setMonthStatus(status);
      })
      .catch((error) => {
        console.error("Error fetching month status:", error);
      });
  }, [selectedMonth]);

  const myCalendarRef = useRef();

  const nextMonthCallback = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const prevMonthCallback = () => {
    setSelectedMonth(addMonths(selectedMonth, -1));
  };

  const handleClickDay = (value, event) => {
    console.log("value of clicked day: ", value);
  };

  return (
    <div className="calendar-container">
      <Grid container columnSpacing={0} rowSpacing={1} alignItems="center">
        <Grid item xs={2}>
          <IconButton onClick={prevMonthCallback}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <div className="react-calendar-date-holder">
            {formatDate(selectedMonth)}
          </div>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={nextMonthCallback}>
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Calendar
        onChange={handleDateChange}
        value={selectedDates}
        selectRange={selectRange}
        allowPartialRange={true}
        showFixedNumberOfWeeks={false}
        onClickDay={handleClickDay}
        maxDetail={"month"}
        inputRef={myCalendarRef}
        tileClassName={getTileClassNameFunction(timesheetData, monthStatus)}
        tileDisabled={({ date }) => {
          return desactivateCalendar;
        }}
        showNeighboringMonth={false}
        activeStartDate={selectedMonth}
      />
    </div>
  );
}

export default CustomCalendar;
