import Header from "../../components/Header";
import EmployeeApprovalCard from "./components/EmployeeApprovalCard/EmployeeApprovalCard";
import { getEmployees, getYearMonthStatus } from "./helpers";
import "./TimeMatePage.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TotalHoursByActivAndUsers from "./components/Reports/TotalHoursByActivAndUsers";
import SelectCustomized from "../../components/input_components/SelectCustomized";
import ApprovalCard from "./components/LeaveRequest/ApprovalCard";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function TimeMateAdminPage() {
  const adminState = useSelector((state) => state.admin);
  // const [employeesList, setEmployeesList] = useState({
  //   myApprovals: [],
  //   otherApprovals: [],
  // });
  const [employeesList, setEmployeesList] = useState([]);
  const [employeesListLeavReq, setEmployeesListLeavReq] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedYearLeaveReq, setSelectedYearLeaveReq] = useState(
    new Date().getFullYear()
  );

  const [yearsTimesheet, setYearsTimesheet] = useState([]);
  const [yearMonthStatus, setYearMonthStatus] = useState({});

  useEffect(() => {
    getYearMonthStatus(setYearMonthStatus);
  }, []);

  useEffect(() => {
    getEmployees(setEmployeesList, selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    getEmployees(setEmployeesListLeavReq, 11, selectedYearLeaveReq);
  }, [selectedYearLeaveReq]);

  useEffect(() => {
    setYearsTimesheet(Object.keys(yearMonthStatus).sort((a, b) => b - a));
  }, [yearMonthStatus]);

  useEffect(() => {
    getEmployees(setEmployeesList, selectedMonth, selectedYear);
  }, [adminState.activeUser]);

  useEffect(() => {
    getEmployees(setEmployeesListLeavReq, 11, selectedYearLeaveReq);
  }, [adminState.activeUser]);

  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper">
          <Header />
          <div className="timemate-block-container">
            <div className="timemate-admin-upper-block">
              <div className="header-flexbox-wrapper-timesheets">
                <div className="timemate-admin-block-title">
                  Pending Timesheets
                </div>
                <div className="timesheet-filter-drop-down">
                  <SelectCustomized
                    label="month"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    select_options={monthNames}
                    values={Array.from(Array(12).keys())}
                    value={selectedMonth}
                    name="month"
                    letterSpacing={0}
                  />
                  <SelectCustomized
                    label="year"
                    sx={{ width: "100%" }}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    select_options={[
                      selectedYear - 1,
                      selectedYear,
                      selectedYear + 1,
                    ]}
                    values={[selectedYear - 1, selectedYear, selectedYear + 1]}
                    value={selectedYear}
                    name="year"
                    letterSpacing={0}
                  />
                </div>
              </div>
              <div className="timemate-admin-column-container">
                {employeesList.map((reviewer) => {
                  return (
                    <div className="reviewer-column">
                      <div className="timemate-admin-column-title">
                        {reviewer.is_active_user
                          ? "My Reviews"
                          : reviewer.reviewer}
                      </div>
                      {reviewer.coachees.map((coachee) => {
                        return (
                          <div className="timemate-admin-left-column-content">
                            <EmployeeApprovalCard
                              key={coachee}
                              employeeId={coachee}
                              monthYear={`${monthNames[selectedMonth]} ${selectedYear}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="timemate-admin-upper-block">
              <div className="header-flexbox-wrapper-timesheets">
                <div className="timemate-admin-block-title">
                  Pending Leave Requests
                </div>
                <div className="timesheet-filter-drop-down leave-request">
                  <SelectCustomized
                    label="year"
                    sx={{ width: "100%" }}
                    onChange={(e) => setSelectedYearLeaveReq(e.target.value)}
                    select_options={[
                      selectedYear - 1,
                      selectedYear,
                      selectedYear + 1,
                    ]}
                    values={[selectedYear - 1, selectedYear, selectedYear + 1]}
                    value={selectedYearLeaveReq}
                    name="year"
                    letterSpacing={0}
                  />
                </div>
              </div>
              <div className="timemate-admin-column-container">
                {employeesListLeavReq.map((reviewer) => {
                  return (
                    <div className="reviewer-column">
                      <div className="timemate-admin-column-title">
                        {reviewer.is_active_user
                          ? "My Reviews"
                          : reviewer.reviewer}
                      </div>
                      {reviewer.coachees.map((coachee) => {
                        return (
                          <div className="timemate-admin-left-column-content">
                            <ApprovalCard
                              key={coachee}
                              employeeId={coachee}
                              year={selectedYearLeaveReq}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="reports-wrapper">
              <div className="timemate-admin-block-title">Reports</div>
              <div className="accordion-reports-wrapper">
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  reportName=" Total hours grouped by activity and user"
                  endpointUrl="/timemate/report-totalhours-by-act-and-user/"
                  fileName="TotalHoursByActivityAndUser.xlsx"
                />
                <TotalHoursByActivAndUsers
                  months={monthNames}
                  reportName=" Total hours on-site grouped by user"
                  endpointUrl="/timemate/report-onsite-hours-by-user/"
                  fileName="TotalHoursOnSiteByUser.xlsx"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeMateAdminPage;
