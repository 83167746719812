import { forwardRef, useState } from "react";
import { Dialog, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PhilicoLogo from "../../../../assets/philico_logo_initials.png";
import TimesheetDataView from "../TimesheetDataView/TimesheetDataView";
import RejectDialogWindow from "./RejectDialogWindow";
import TimesheetOverviewCard from "./TimesheetOverviewCard";
import "./TimesheetOverviewCard.css";
import FilterCalendar from "./FilterCalendar";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApprovalDialogWindow = ({
  employeeFullName,
  totalHours,
  timesheetStatus,
  openDialog,
  closeDialogCallback,
  timesheetData,
  setTimesheetData,
  rejectCallback,
  approveCallback,
  reopenCallback,
  employeeId,
  monthYear,
}) => {
  const [open, setOpen] = useState(false);
  const timesheetStatusLocal = timesheetStatus
    ? timesheetStatus.toLowerCase()
    : "pending";
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={closeDialogCallback}
      TransitionComponent={Transition}>
      <AppBar
        sx={{ position: "sticky", backgroundColor: "white" }}
        fullScreen={true}>
        <Toolbar sx={{ height: "100px" }}>
          <img
            src={PhilicoLogo}
            style={{ marginLeft: "25px", width: "64px", height: "64px" }}
          />

          <Typography
            sx={{ ml: 2, flex: 1, color: "#004170", fontWeight: "bold" }}
            variant="h5"
            component="div">
            {`Approval - ${employeeFullName}`}
          </Typography>

          <Typography
            sx={{ ml: 2, flex: 1, color: "#004170", fontWeight: "bold" }}
            variant="h5"
            component="div">
            {`Total Hours: ${totalHours}`}
          </Typography>

          {timesheetStatusLocal === "submitted" && (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  setOpen(true);
                }}
                sx={{ mr: 1, width: "100px" }}>
                Reject
              </Button>
              <Button
                color="inherit"
                onClick={approveCallback}
                sx={{ mr: "50px", width: "100px" }}>
                Approve
              </Button>
            </>
          )}

          {(timesheetStatusLocal === "approved" ||
            timesheetStatusLocal === "rejected") && (
            <Button
              color="inherit"
              onClick={reopenCallback}
              sx={{ mr: "50px", width: "100px" }}>
              Reopen
            </Button>
          )}

          <IconButton autoFocus onClick={closeDialogCallback} sx={{ mr: 0 }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="flexbox-wrapper-timesheet-overview">
        <FilterCalendar
          monthYear={monthYear}
          setTimesheetData={setTimesheetData}
          employeeId={employeeId}
        />
        <TimesheetOverviewCard employeeId={employeeId} monthYear={monthYear} />
      </div>
      <TimesheetDataView
        timesheetData={timesheetData}
        employeeId={employeeId}
        enableRowActions={false}
      />
      <RejectDialogWindow
        open={open}
        setOpen={setOpen}
        rejectCallback={rejectCallback}
      />
    </Dialog>
  );
};

export default ApprovalDialogWindow;
